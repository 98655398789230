


<template>

    <v-row>
        <v-col
                cols="12"
                sm="6"
        >
            <v-card
                    class="pa-2"
                    elevation="0"
            >
                <h2>{{header}}</h2>
                <v-alert type="error"
                         close-text="Закрыть сообщение"
                         dismissible
                         v-model="alertError.show"
                         @click="navBack()"
                >
                    {{alertError.text}}
                </v-alert>
                <v-alert type="warning"
                         close-text="Закрыть сообщение"
                         dismissible
                         v-model="alertWarning.show"
                         @click="navBack()"
                >
                    {{alertWarning.text}}
                </v-alert>
                <v-btn v-if="navBackShow" block
                       color="primary"
                       @click.prevent="navBack()"
                       elevation="10"
                >
                    Обратно
                </v-btn>
                <v-card
                        class="mt-6 pa-2"
                        elevation="10"
                        outlined
                        :loading="loading"
                >


                    <v-list-item-group
                            shaped
                            color="primary"
                            v-model="selectedItem"
                    >
                        <v-list-item
                                v-for="(e,i) in arr"
                                :key="i"
                                @click="onNext(i)"
                                :class="{disabled: e.FREE=='0'}"
                                :disabled="e.FREE=='0'"
                        >
                            <v-list-item-icon>
                                <v-icon v-if="e.FREE=='0'"
                                        color="gray"
                                >
                                    {{iconNo}}
                                </v-icon>
                                <v-icon v-else
                                        color="green"
                                >
                                    {{iconYes}}
                                </v-icon>

                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title v-text="e.DDATE"></v-list-item-title>
                                <v-list-item-subtitle v-text="e.NNAME"></v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-card>
            </v-card>
        </v-col>
        <v-col
                cols="12"
                sm="6"
        >
            <v-card
                    class="pa-2"
                    elevation="0"
            >
                <h2>Выбрано</h2>
                <v-card
                        class="mt-6 pa-2"
                        elevation="0"
                        outlined
                >


                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title v-html="LPU.name"/>
                            <v-list-item-subtitle v-text="LPU.address"/>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title v-text="FIN.name"/>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title v-text="AGE.name"/>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title v-text="MEDG.PANELCAPTION1"/>
                            <v-list-item-subtitle v-text="MEDG.PANELCAPTION2"/>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title v-text="MED.NNAME"/>
                        </v-list-item-content>
                    </v-list-item>


                </v-card>
            </v-card>
        </v-col>

    </v-row>



</template>

<style>
    .disabled {
        pointer-events: none;
        text-decoration: line-through;
        color: grey;
    }

</style>

<script>
    import post, {postLog} from '../tools/post'
    import {toBack,  toNext, thisLog} from '@/components/app/js/globals'
    import {mdiViewDay, mdiViewDayOutline} from '@mdi/js';

    export default {
        name: 'Ddate',
        data: () => ({
            name: 'Ddate',
            header: 'Выберите дату',
            loading: true,
            log: true,
            selectedItem: null,
            iconYes: mdiViewDay ,
            iconNo: mdiViewDayOutline,
            alertError: {
                show: false,
                text: null
            },
            alertWarning: {
                show: false,
                text: null
            },
            arr: [
                /*
                {
                    NPP : '1',
                    DDATE : '03.11.2020',
                    NNAME : '03 ноября 2020 г. Вторник (талонов своб/всего: 7/60)' //Upper, т.к. так передается из REST API
                }

                 */
            ]
        }),
        computed: {
            LPU() {
                return this.$store.getters.LPU;
            },
            FIN() {
                return this.$store.getters.FIN;
            },
            AGE() {
                return this.$store.getters.AGE;
            },
            MEDG() {
                return this.$store.getters.MEDG;
            },
            MED() {
                return this.$store.getters.MED;
            },
            DDATE() {
                return this.$store.getters.DDATE;
            },
            is_actual() {
                return (this.$store.getters.LPU.actual && this.$store.getters.LPU.actual==='1') &&
                       (this.$store.getters.FIN.actual && this.$store.getters.FIN.actual==='1') &&
                       (this.$store.getters.AGE.actual && this.$store.getters.AGE.actual==='1') &&
                       (this.$store.getters.MEDG.actual && this.$store.getters.MEDG.actual==='1') &&
                       (this.$store.getters.MED.actual && this.$store.getters.MED.actual==='1')
            },
            navBackShow() {
                //console.log('-----',this.alertError.show, this.alertWarning.show)
                return (this.alertError.show || this.alertWarning.show)
            }

        },
        async beforeMount() {
            try {
                if (this.is_actual) {

                    const url = this.$store.getters.LPU.resturl + '/getdate'
                    thisLog(this, ['url', url])
                    const data = {
                        nppkard: '',
                        nppmed: this.MED.NPP,
                        nppspec: '',
                        opt: this.FIN.opt,
                        db: '',
                        de: '',
                        tb: '',
                        te: ''
                    }


                    try {
                        //console.log('post')
                        let res = await post(url, data)
                        //console.log('post', res, res.data)
                        //console.log('post', res)
                        this.arr=res.data
                        thisLog(this, [` DDATE >>>>>>>>> `, this.arr])
                        if (this.arr.length == 0) {
                            this.alertWarning.text = `Не найдено данных по врачу "${this.$store.getters.MED.NNAME}"`
                            this.alertWarning.show = true
                            //pushBack(this,{nodata: true, prev: true})
                        }
                    } catch (err) {
                        this.alertError.text = err
                        this.alertError.show = true
                        //console.log('err')
                        //pushErr(this, err)
                    }
                } else {
                    this.alertWarning.text = "Не найдено данных - повторите попытку еще раз"
                    this.alertWarning.show = true
                    this.navBack()  //каскадный откат
                    //pushBack(this,{nodata: true})
                }
                const _DDATE = this.$store.getters.DDATE
                this.selectedItem = (_DDATE && typeof _DDATE.selectedItem === 'number' ? _DDATE.selectedItem : null)

                //подтвердить актуальность selectedItem при переходах!!!
                //console.log(this.arr)
                if( !(typeof this.selectedItem == 'number' && this.selectedItem+1 <= this.arr.length ) ){
                    this.$store.dispatch('SET_DDATE_ACTUAL', '0')
                }
            }catch(e){
                alert(e)
                postLog(this.$store.getters.LPU.resturl, { alert: e, place: 'Ddate#1' } )
            }


            this.loading=false
        },
        methods: {
            onNext(i){
                try {
                    //console.log('selected############# ',i,this.selectedItem, {selectedItem: i, ...this.arr[i]})
                    this.$store.dispatch('SET_DDATE', {selectedItem: i, ...this.arr[i]})
                    toNext(this)
                }catch(err){
                    this.alertError.text = err
                    this.alertError.show = true
                    postLog(this.$store.getters.LPU.resturl, { err: err, place: 'Ddate#2' } )
                }

            },
            async navBack(){
                toBack(this)
            }

        }



    }
</script>
